[data-mode="mobile"] {
  .calendar {
    width: 100%;
    height: 136px;
    left: 0;
    position: absolute;
    margin-top: 4.8rem;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    background: #F7F5F3;
    padding: 0 20px 15px 20px;
    color: var(--primary-color);
    font-size: 0.75rem;

    p {
      margin-top:18px ;
    }

    &:before {
      content: '';
      border-bottom: 1px solid var(--primary-color);
      width: 100%;
      margin: 0 auto;
      position: relative;
      z-index: 999;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .calendar-close {
      position: absolute;
      top: 23px;
      right: 12px;
      width: 24px;
      height: 24px;
      background: url('../../../../assets/icons/close.svg') no-repeat;
      filter: var(--filter-svg-color);
      cursor: pointer;
    }

  }
}
