
  .calendar-btn {
    width: 166px;
    height: 45px;
    border-radius: 30px;
    border: 1px solid var(--primary-color);
    padding: 0 35px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    cursor: pointer;
    color: var(--primary-color);
    transition: 0.2s;

    &:hover {
      background: var(--primary-color);
      color: #fff;

      span {
        filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(284deg) brightness(104%) contrast(104%);
      }
    }

    &:active {
      border: 1px solid #fff;
    }

    &.active {
      transition: 0.2s;
      background: var(--primary-color);
      color: white;

      span {
        filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(327deg) brightness(104%) contrast(101%);
      }
    }

    span {
      height: 18px;
      width: 18px;
      background: url('../../assets/icons/calendar.svg') no-repeat center;
      filter: var(--filter-svg-color);
    }

    p {
      font-family: 'GrandParis-Regular';
      font-size: 14px;
      font-weight: 300;
      line-height: 18px;
      letter-spacing: 0em;
      text-align: left;

      margin: 0;
    }
  }

  .calendar {
    width: 100%;
    height: 136px;
    left: 0;
    position: absolute;
    margin-top: 21px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    color: var(--primary-color);

    #date-slider {
      label {
        position: relative;
        flex: 1;
        min-width: 0; 
        font-family: 'GrandParis-Regular';
        
        font-weight: 300;
        line-height: 17px;
        white-space: break-spaces;

        &::before {
          position: absolute;
          content: "";
          width: 100%;
          height: 1px;

          bottom: 14px;
          background-image: repeating-linear-gradient(to right, var(--primary-color), var(--primary-color) 3px, transparent 3px, transparent 10px);
          background-size: 100% 1px;
          background-repeat: no-repeat;
          z-index: -1;
        }

        &:first-child::before {
          width: 50%;
          right: 0;
        }

        &:last-child::before {
          width: 50%;
          left: 0;
        }
        
        cursor: pointer;

        &:has(input[type="radio"]:checked) {
          font-family: "GrandParis-Bold";
          font-weight: 500;
        }

        input[type="radio"] {
          appearance: none;
          width: 11px;
          height: 11px;
          border: 1px solid var(--primary-color);
          background: #fff;
          border-radius: 50%;
          transition: all 0.15s ease-in-out;
          margin: 9px 0;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          &::before {
            content: "";
            display: block;
            width: 11px;
            height: 11px;
            border-radius: 50%;
            transform: scale(0);
            transition: 120ms transform ease-in-out;
            box-shadow: inset 1em 1em var(--primary-color);
            background-color: var(--primary-color);
            margin: 0;
          }

          &:checked::before {
            transform: scale(1);
          }

          &:not(:checked):hover {
            transform: scale(1.25);
          }

          &:checked {
            width: 19px;
            height: 19px;
            margin: 5px 0;
          }
      }

      &.active input[type="radio"] {
        background: var(--primary-color);
      }
    }
  }

  /*.calendar-close-btn {
    background-color: #fff;
    background-position: center center ;
    position: absolute;
    top: 12px;
    right: 12px;
    padding: 12px;
    background-size: 11px;
    cursor: pointer;
    border-radius: 50%;
    width: 14px;
    height: 14px;

    .calendar-close {
      background-color: var(--primary-color);
      -webkit-mask-image: url('../../assets/icons/close-blue.svg');
      mask : url('../../assets/icons/close-blue.svg') no-repeat center center;
      width: 10px;
      height: 10px;
      position: absolute;
      top: 7px;
      right: 7px;
    }

    &:hover {
      background-color: var(--primary-color);
      .calendar-close{
        background-color: #fff;
      }
    }
  }*/

  }

[data-mode="desktop"], [data-mode="tablet"] {
  .calendar {
    max-width: 523px;
    border: 1px solid var(--primary-color);
    border-radius: 10px;
    background: #fff;
    z-index: 1;
    padding: 20px 20px 15px 20px;
    font-size: 14px;
  
    & > p {
      padding-left: 15px;
    }
  }

  .calendar-close-btn {
    position: absolute;
    top: 12px;
    right: 12px;
    padding: 12px;
    background-size: 11px;
    cursor: pointer;
    border-radius: 50%;
    width: 14px;
    height: 14px;
    
    .calendar-close {
      background-color: var(--primary-color);
      -webkit-mask-image: url('../../assets/icons/close-blue.svg');
      mask : url('../../assets/icons/close-blue.svg') no-repeat center center;
      width: 10px;
      height: 10px;
      position: absolute;
      top: 7px;
      right: 7px;
    }

    &:hover {
      background-color: var(--primary-color);
      .calendar-close{
        background-color: #fff;
      }
    }
  }
} 
